<template>
  <div class="page-container">
    <button-all-header
      :contentBtnAdd="'Thêm Menu'"
      :arrayExcel="[]"
      :hideDowload="false"
      :hideExportFile="false"
      :hideImportFile="false"
      :hideDelete="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,

      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: số hiệu văn bản-->
        <span v-if="props.column.field === 'Code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            class="ml-2"
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>
    <MyPagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />
    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
    <add-menu
      :id="modalCreateMenu"
      :data="dataDetail"
      @handleAddMenu="handleAddMenu"
    />
  </div>
</template>

<script>
import {
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import AddMenu from './AddMenu.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constant/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BBadge,
    ButtonAllHeader,
    FeatherIcon,
    VBTooltip,
    AddMenu,
    ConfirmModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      modalType: '',
      modalCreateMenu: 'modalCreateMenu',
      totalRecord: 0,
      dataList: [],
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'modalDelete',
      modalContent: '',
      columns: [
        {
          label: 'MÃ MENU',
          field: 'Code',
          sortable: false,
        },
        {
          label: 'NHÓM ĐƠN VỊ',
          field: 'groupName',
          sortable: false,
        },
        {
          label: 'TÊN MENU',
          field: 'title',
          sortable: false,
        },
        {
          label: 'ROUTER',
          field: 'route',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],

      showBtnMultiDelete: false,
      idDetail: '',
      dataDetail: {},
    }
  },

  created() {
    this.getLisst()
  },

  methods: {

    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalCreateMenu)
    },

    async showModalEdit(id) {
      this.modalType = 'edit'
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.GET_DETAIL}${id}`)
      this.dataDetail = data
      this.$bvModal.show(this.modalCreateMenu)
    },

    async getLisst() {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST, {
        params: this.urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.getLisst()
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    async deleteAction() {
      this.$showAllPageLoading()
      const payload = {
        id: this.deleteIds[0],
      }
      await axiosApiInstance.delete(ConstantsApi.DELETE, { params: payload }).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getLisst(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async handleAddMenu(param) {
      if (this.modalType === 'add') {
        const payload = {
          ...param,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.ADD, payload).then(() => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.getLisst()
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      } else if (this.modalType === 'edit') {
        const payload = {
          id: this.idDetail,
          ...param,
        }
        this.$showAllPageLoading()
        const res = await axiosApiInstance.post(ConstantsApi.EDIT, payload)
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Chỉnh sửa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.getLisst()
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Chỉnh sửa không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      }
    },

    search(val) {
      this.urlQuery.search = val
      this.urlQuery.pageNumber = 1
      this.urlQuery.pageSize = 10
      this.getLisst()
    },

  },
}
</script>

<style>

</style>
