<template>
  <div>

    <b-modal
      :id="id"
      title="Thêm Menu"
      centered
      :no-close-on-backdrop="onBackdrop"
      class="page-container-table"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <div class="container-win-tao">
        <b-form-group>
          <span>Code</span>
          <b-form-input
            v-model="dataInput.code"
          />
        </b-form-group>
        <b-form-group>
          <span>Router</span>
          <b-form-input
            v-model="dataInput.router"
          />
        </b-form-group>
        <b-form-group>
          <span>Tên menu</span>
          <b-form-input
            v-model="dataInput.name"
          />
        </b-form-group>
        <b-form-group>
          <span>Nhóm</span>
          <v-select
            v-model="dataInput.groupId"
            :reduce="item => item.key"
            label="value"
            :options="arrayGroup || []"
            @open="open"
            @close="close"
          />
        </b-form-group>
        <!-- <b-form-group>
          <span>Kiểu người dùng</span>
          <v-select
            v-model="dataInput.userTypeId"
            :reduce="item => item.key"
            label="value"
            :options="arrayUserType || []"
          />
        </b-form-group> -->
        <b-form-group>
          <span>Quyền người dùng</span>
          <v-select
            v-model="dataInput.roleId"
            :reduce="item => item.key"
            label="value"
            multiple
            :close-on-select="false"
            :options="arrayDefaultRole || []"
            @open="open"
            @close="close"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="dataInput.isCheckDuplicated"
          >
            Cho phép trùng Router
          </b-form-checkbox>
        </b-form-group>

      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constant/ConstantsApi'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    vSelect,
    BFormCheckbox,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      onBackdrop: false,
      arrayGroup: [],
      arrayUserType: [],
      arrayDefaultRole: [],
      array: [
        'win',
        'Triều',
        'Minh',
      ],
      dataInput: {
        name: '',
        router: '',
        code: '',
        groupId: null,
        roleId: null,
        userTypeId: null,
        isCheckDuplicated: false,
      },
    }
  },

  watch: {
    data(val) {
      if (val) {
        this.dataInput = {
          name: this.data.name,
          router: this.data.router,
          code: this.data.code,
          groupId: this.data.groupId,
          roleId: this.data.roleId,
          userTypeId: this.data.userTypeId,
          isCheckDuplicated: this.data.isCheckDuplicated,

        }
      }
    },
  },
  created() {
    this.getAllGroup()
    this.getAllUserType()
    this.getDefaultRole()
  },
  methods: {
    async getAllGroup() {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_GROUP)
      this.arrayGroup = data?.data
    },
    async getAllUserType() {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_USER_TYPE)
      this.arrayUserType = data?.data
    },
    async getDefaultRole() {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_DEFAULT_ROLE)
      this.arrayDefaultRole = data?.data
    },
    accept() {
      this.$emit('handleAddMenu', this.dataInput)
    },
    resetData() {
      this.dataInput = {
        name: '',
        router: '',
        code: '',
        groupId: null,
        roleId: null,
        userTypeId: null,
      }
    },

    open() {
      this.onBackdrop = true
    },
    close() {
      setTimeout(() => {
        this.onBackdrop = false
      }, 150)
    },
  },
}
</script>

<style lang="scss">
// .container-win-tao {
//   width: 500px;
//   margin: 0 auto;
// }
</style>
