export default {

  LIST: 'UserTypev2/fs-paging',
  GET_DETAIL: '/UserTypev2/fs-get-by-id/',
  ADD: 'UserTypev2/fs-add-new',
  EDIT: 'UserTypev2/fs-update',
  DELETE: 'UserTypev2/fs-delete',

  GET_GROUP: 'UserTypev2/fs-group',
  GET_USER_TYPE: 'UserTypev2/fs-user-type',
  GET_DEFAULT_ROLE: 'UserTypev2/fs-default-role',

}
